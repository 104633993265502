import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css'
import API from '@/api/index'
import VueFullPage from 'vue-fullpage.js'
import 'fullpage.js/vendors/scrolloverflow'
import VModal from 'vue-js-modal'
import SwiperClass, { Navigation, Pagination,} from "swiper";
SwiperClass.use([Navigation, Pagination]);
import 'swiper/swiper-bundle.css';
import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper,)

Vue.config.productionTip = false
Vue.use(API);
Vue.use(ElementUI, {locale});
Vue.use(VModal); 
Vue.use(VueFullPage);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


