<template>
    <modal name="customer-inquire-modal" class="customer-inquire-modal"
           :width="'100%'"
           :height="'100%'"
           :maxWidth="520"
           :maxHeight="250"
           :pivotY="0.5"
           :adaptive="true"
           :scrollable="true"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
           @opened="handlerOpened"
    >
        <div class="modal_wrap">
            <div class="modal_title">
                이옷 고객 문의 안내
            </div>
            <div class="modal_content_container">
                <div class="modal_content">카카오톡 : @이옷</div>
                <div class="modal_content">이메일 : office@e-ot.io</div>
                <div class="modal_content">운영시간 : 10:00 ~ 17:00</div>
                <div class="modal_content">점심시간 : 12:00 ~ 13:00</div>
                <div class="modal_content">주말, 공휴일 휴무</div>
            </div>
        </div>
        <el-button class="modal_close_btn" @click="modalClose"></el-button>
    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "CustomerInquireModal",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerBeforeClose() {
        },
        handlerOpened() {
        },
        modalClose() {
            this.$modal.hide('customer-inquire-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
