<template>
    <modal name="terms-modal" class="terms-modal"
           :width="'100%'"
           :height="'100%'"
           :maxWidth="520"
           :maxHeight="800"
           :pivotY="0.5"
           :adaptive="true"
           :scrollable="true"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
           @opened="handlerOpened"
    >
        <terms-component v-if="termsType !== ''" :termsType="termsType"></terms-component>
        <el-button class="modal_close_btn" @click="modalClose"></el-button>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import TermsComponent from "@/components/terms/TermsComponent";

export default {
    name: "TermsModal",
    components: {TermsComponent},
    mixins: [],
    props: {
        type: '',
    },
    data() {
        return {
            termsType: '',
        }
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.type)) {
                event.stop();
                return false;
            }

            this.termsType = event.params.type;
        },
        handlerBeforeClose() {
        },
        handlerOpened() {
        },

        modalClose() {
            this.$modal.hide('terms-modal');
        },
    },
}
</script>

<style scoped>

</style>
