<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>

export default {
    name: "App",
    mixins: [],
    components: {},
    props: {},
    data() {
        return {
        }
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {

    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>
<style lang="scss">
    @import "assets/scss/index.scss";
</style>
