const termsConfig = {
    service : {
        termName: 'cs_stipulation',
    },

    privacy: {
        termName: 'cs_privacy',
    },

    privacy_provide : {
        termName: 'cs_privacy_provide',
    }
}

export default {
    methods: {
        returnTermsConfig(type) {
            return termsConfig[type];
        }
    },
}