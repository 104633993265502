import Vue from 'vue';
import {request, setAuthInHeader} from "@/api/request";

Vue.prototype.$api = {
    $common: { // 공통 정보 관련 api
        getConfig() { //안내문 get
            return request.get("config/service")
                .then(({data}) => data);
        },
        getConfigInfo(params) {
            return request.get(`config/info`,params).then(({data}) => data)
        },
    },
    $handler: {
        authHeader : setAuthInHeader
    }
}
export default Vue.prototype.$api;