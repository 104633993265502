<template>
    <div
        class="scroll-eot"
        :class="[{'locked' : scrollLoacked && !goingTop},{'goingTop' : goingTop}]"
        id="container"
        @touchstart="createAreaBtn($event)"
        @touchend="endBtnArea()"
        @touchmove="setBtnArea"
    >
        <div class="container-max">
            <div class="wrap-background-eot gray top">
                <div class="logo-eot"></div>
                <div class="ta-c b36600 c-01 lh-4 pb40 f text-top">
                    <span class="text-animation-left">
                        <div>스타일의&nbsp;</div>
                    </span>
                    <span class="text-animation-left">
                        <div>모든 것</div>
                    </span>
                    <br />
                    <span class="text-animaion-right">
                        <div><span>이옷</span>에서</div>
                    </span>
                    <br />
                    <span class="text-animation-left">
                        <div>쉽고&nbsp;</div>
                    </span>
                    <span class="text-animation-left">
                        <div>간편하게</div>
                    </span>
                </div>
                <div class="ta-c">
                    <button class="button-down-app" @click="CheckBrowser()">앱 다운로드</button>
                </div>
                <div class="pt60 pb75">
                    <div class="wrap-recommend">
                        <div class="wrapper-recommend">
                            <div class="flip-recommend" id="imgFlip">
                                <div class="content-recommend-top" :class="recentimageIdx"></div>
                            </div>
                            <div class="content-recommend-bottom" :class="recentimageIdx"></div>
                            <div class="content-recommend-top recent" :class="imageIdx"></div>
                            <div class="flip-recommend recent" id="imgFlip2">
                                <div class="content-recommend-bottom recent" :class="imageIdx"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ta-c b24400 c-01 pb100 text-intro">
                    나에게 맞는
                    <br />스타일을 찾아보세요.
                    <br />지금까지 경험 못했던
                    <br />쉽고 편리한 서비스,
                    <br />이옷과 함께하면
                    <br />당신의 일상도 새로워질 거예요.
                </div>
            </div>

            <div class="wrap-background-eot pl0 pr0">
                <div
                    class="title-eot-common pl24 pr24"
                    id="tossScrollTop"
                    :class="{'disabled' : !scrollText1}"
                >스타일추천</div>
                <div class="subtitle-eot-common pl24 pr24">
                    <span :class="{'disabled' : !scrollText1}">우리는&nbsp;</span>
                    <span :class="{'disabled' : !scrollText2}">1억 개의</span>
                    <br />
                    <span :class="{'disabled' : !scrollText3}">상품</span>
                    <span :class="{'disabled' : !scrollText4}">&nbsp;진열이&nbsp;</span>
                    <span :class="{'disabled' : !scrollText5}">아닌</span>
                    <br />
                    <span :class="{'disabled' : !scrollText6}">단</span>
                    <span :class="{'disabled' : !scrollText7}">&nbsp;1개의&nbsp;</span>
                    <span :class="{'disabled' : !scrollText8}">스타일링을</span>
                    <br />
                    <span :class="{'disabled' : !scrollText9}">추천합니다</span>
                </div>
                <div class="toss-slide-test" id="scrollToss">
                    <div class="toss-width-test"></div>
                    <div class="wrap-recommend ml20 mr20 image-2-1 fs-0"></div>
                    <div class="wrap-recommend ml20 mr20 image-2-2 fs-0">
                        <div class="filter-recommend">
                            <div class="box-recommend image-2-2 bdrs22"></div>
                        </div>
                    </div>
                    <div class="toss-width-test"></div>
                </div>
            </div>

            <div class="wrap-background-eot pl0 pr0">
                <div
                    class="title-eot-common pl24 pr24"
                    id="tossScrollTopSec"
                    :class="{'disabled' : !scrollText10}"
                >스마트피팅</div>
                <div class="subtitle-eot-common pl24 pr24">
                    <span :class="{'disabled' : !scrollText10}">한 번의&nbsp;</span>
                    <span :class="{'disabled' : !scrollText11}">터치로</span>
                    <br />
                    <span :class="{'disabled' : !scrollText12}">추천받은&nbsp;</span>
                    <span :class="{'disabled' : !scrollText13}">스타일링을</span>
                    <br />
                    <span :class="{'disabled' : !scrollText14}">피팅&nbsp;</span>
                    <span :class="{'disabled' : !scrollText15}">할 수 있는</span>
                    <br />
                    <span :class="{'disabled' : !scrollText16}">이런&nbsp;</span>
                    <span :class="{'disabled' : !scrollText17}">서비스&nbsp;</span>
                    <span :class="{'disabled' : !scrollText18}">써보셨나요?</span>
                </div>
                <div class="toss-slide-test" id="scrollTossSec">
                    <div class="toss-width-test"></div>
                    <div class="wrap-recommend ml20 mr20 image-3-1 fs-0"></div>
                    <div class="wrap-recommend ml20 mr20 fs-0">
                        <div class="filter-recommend">
                            <div class="box-recommend image-3-2 bdrs22"></div>
                        </div>
                    </div>
                    <div class="toss-width-test"></div>
                </div>
                <div class="b24600 c-01 pb20 pl24 pr24 text-style">
                    이옷의 스마트피팅 서비스로
                    <br />나와의 어울림에 대한
                    <br />확인을 바로 할 수 있어요.
                </div>
                <div class="b20400 c-5 pb100 pl24 pr24 text-style-caption">
                    다양한 브랜드의 베스트 아이템뿐 아니라
                    <br />고가의 명품 브랜드 피팅도 모두 가능해요.
                </div>
            </div>
            <div class="wrap-background-eot">
                <div
                    class="title-eot-common"
                    id="tossScrollTopthi"
                    :class="{'disabled' : !scrollText19}"
                >매거진</div>
                <div class="subtitle-eot-common">
                    <span :class="{'disabled' : !scrollText19}">바쁜</span>
                    <span :class="{'disabled' : !scrollText20}">&nbsp;현대사회에</span>
                    <br />
                    <span :class="{'disabled' : !scrollText21}">빠르게</span>
                    <span :class="{'disabled' : !scrollText22}">&nbsp;변화하는&nbsp;</span>
                    <span :class="{'disabled' : !scrollText23}">패션</span>
                    <br />
                    <span :class="{'disabled' : !scrollText24}">트렌드를</span>
                    <span :class="{'disabled' : !scrollText25}">&nbsp;쉽게&nbsp;</span>
                    <span :class="{'disabled' : !scrollText26}">이해하고</span>
                    <br />
                    <span :class="{'disabled' : !scrollText27}">따라</span>
                    <span :class="{'disabled' : !scrollText28}">&nbsp;할 수 있는</span>
                    <br />
                    <span :class="{'disabled' : !scrollText29}">도움을</span>
                    <span :class="{'disabled' : !scrollText30}">&nbsp;주는&nbsp;</span>
                    <span :class="{'disabled' : !scrollText31}">콘텐츠까지</span>
                </div>
                <div class="pt60 pb75">
                    <div class="wrap-recommend box-filter" id="scrollLock">
                        <div class="filter-recommend">
                            <div class="box-recommend image0 slide-fade-enter-to"></div>
                            <Transition name="slide-fade">
                                <div class="box-recommend image1" v-if="activeIndex >= 1"></div>
                            </Transition>
                            <Transition name="slide-fade">
                                <div class="box-recommend image2" v-if="activeIndex === 2"></div>
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrap-background-eot gray">
                <div
                    class="title-eot-common"
                    id="tossScrollTopFou"
                    :class="{'disabled' : !scrollText32}"
                >또다른 이옷</div>
                <div class="subtitle-eot-common pb60">
                    <span :class="{'disabled' : !scrollText33}">이런</span>
                    <span :class="{'disabled' : !scrollText34}">&nbsp;서비스도&nbsp;</span>
                    <span :class="{'disabled' : !scrollText35}">있어요</span>
                </div>
                <div
                    class="icon-other-common brand"
                    :style="`background-position: 50% ${50+scrollIcon}%`"
                ></div>
                <div class="b24600 c-01 pb8">브랜드 추천</div>
                <div class="b20400 c-5 pb60 text-service">
                    이옷에서 엄선한 브랜드의
                    <br />제품을 확인할 수 있어요
                </div>
                <div
                    class="icon-other-common plan"
                    :style="`background-position: 50% ${50+scrollIconTow}%`"
                ></div>
                <div class="b24600 c-01 pb8">기획전</div>
                <div class="b20400 c-5 pb100 text-service">
                    매거진 형태의 기획전을 통해서
                    <br />제품 정보 및 스타일링 가이드까지
                    <br />한 번에 확인해 보세요
                </div>
            </div>
            <div class="wrap-background-eot">
                <div class="title-eot-common" id="tossScrollTopFif">
                    <span :class="{'disabled' : !scrollText37}">데이터로</span>
                    <span :class="{'disabled' : !scrollText38}">&nbsp;보는&nbsp;</span>
                    <span :class="{'disabled' : !scrollText39}">이옷</span>
                </div>
                <div class="subtitle-eot-common pb60">
                    <span :class="{'disabled' : !scrollText40}">AI가&nbsp;</span>
                    <span :class="{'disabled' : !scrollText41}">학습하는</span>
                    <br />
                    <span :class="{'disabled' : !scrollText42}">개인화&nbsp;</span>
                    <span :class="{'disabled' : !scrollText43}">추천 데이터</span>
                </div>
                <div id="boxCounter">
                    <div class="box-eot-data">
                        <div class="icon-eot-data people"></div>
                        <div class="title-eot-data">한달 사용 회원 수</div>
                        <div class="count-eot-data he" data-rate="657320">657,320회</div>
                    </div>
                    <div class="box-eot-data">
                        <div class="icon-eot-data style"></div>
                        <div class="title-eot-data">스타일 추천 조회 수</div>
                        <div class="count-eot-data he" data-rate="6504453">6,504,453회</div>
                    </div>
                    <div class="box-eot-data">
                        <div class="icon-eot-data click"></div>
                        <div class="title-eot-data">누적 찜 제품 수</div>
                        <div class="count-eot-data he" data-rate="2789421">2,789,421회</div>
                    </div>
                    <div class="box-eot-data">
                        <div class="icon-eot-data label"></div>
                        <div class="title-eot-data">라벨링 등록 제품 수</div>
                        <div class="count-eot-data gun" data-rate="358762">358,762건</div>
                    </div>
                    <div class="box-eot-data mb100">
                        <div class="icon-eot-data type"></div>
                        <div class="title-eot-data">추천 가능한 스타일 종류</div>
                        <div class="count-eot-data gun" data-rate="527021">527,021건</div>
                    </div>
                </div>
            </div>
            <div class="wrap-eot-footer">
                <div class="b20600 c-5 pb20">(주)신사유람단</div>
                <div class="b14400 c-5 pb4">대표 강성열</div>
                <div class="b14400 c-5 pb4">사업자등록번호 390-86-01250</div>
                <div class="b14400 c-5 pb40">서울특별시 강남구 논현로 507 1903호</div>
                <div class="b14400 c-5 pb16">카카오톡 : @이옷</div>
                <div class="b14400 c-5 pb16">이메일 : office@e-ot.io</div>
                <div class="b14400 c-5 pb16">운영시간 : 10:00 ~17:00</div>
                <div class="b14400 c-5 pb16">점심시간 12:00 ~ 13:00</div>
                <div class="b14400 c-5 pb40">주말, 공휴일 휴무</div>
                <div class="box-flex">
                    <div
                        class="b14400 c-5 pb16 btn-term"
                        @click="$modal.show('terms-modal', {type:'service'})"
                    >서비스 이용약관</div>
                </div>
                <div class="box-flex">
                    <div
                        class="b14400 c-5 pb16 btn-term"
                        @click="$modal.show('terms-modal', {type:'privacy'})"
                    >개인정보 처리방침</div>
                </div>
                <div class="box-flex">
                    <div
                        class="b14400 c-5 pb40 btn-term"
                        @click="$modal.show('terms-modal', {type:'privacy_provide'})"
                    >
                        개인정보
                        제공내용
                    </div>
                </div>
                <button class="button-eot-alliance mb60" @click="$modal.show('alliance-modal')">제휴문의</button>
                <div class="box-flex gap8">
                    <button
                        class="icon-footer-common insta"
                        @click="onClickRedirect(instar_web_url)"
                    ></button>
                    <button
                        class="icon-footer-common facebook"
                        @click="onClickRedirect(facebook_url)"
                    ></button>
                    <button
                        class="icon-footer-common naver"
                        @click="onClickRedirect(naver_blog_url)"
                    ></button>
                    <button
                        class="icon-footer-common youtube"
                        @click="onClickRedirect(youtube_url)"
                    ></button>
                </div>
            </div>
            <div class="wrap-fixed-top">
                <button class="fixed-eot-top" @click="scrollTop()">
                    <div class="b16600 c-black">TOP</div>
                    <div class="icon-eot-top"></div>
                </button>
            </div>
        </div>
        <alliance-modal></alliance-modal>
        <customer-inquire-modal></customer-inquire-modal>
        <terms-modal></terms-modal>
    </div>
</template>
<script>
import AnimatedNumber from "animated-number-vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";

import "swiper/swiper.min.css";
import TermsModal from "@/components/modal/TermsModal";
import CustomerInquireModal from "@/components/modal/CustomerInquireModal";
import AllianceModal from "@/components/modal/AllianceModal";

import SwiperClass, { Autoplay } from "swiper";

SwiperClass.use([Autoplay]);
import "swiper/swiper-bundle.css";
import VueAwesomeSwiper from "vue-awesome-swiper";

Vue.use(VueAwesomeSwiper);

import Vue from "vue";

export default {
    name: "home",
    mixins: [],
    components: {
        AllianceModal,
        CustomerInquireModal,
        TermsModal,
        AnimatedNumber,
        Swiper,
        SwiperSlide,
    },
    props: {},
    data() {
        return {
            appStore_url: "https://apps.apple.com/app/id1499587318",
            playStore_url:
                "https://play.google.com/store/apps/details?id=com.dgmonglab.eot",
            instar_web_url: "https://www.instagram.com/eot__style/?hl=ko",
            instar_app_url:
                "https://instagram.com/eot__style?igshid=YmMyMTA2M2Y=",
            facebook_url:
                "https://www.facebook.com/profile.php?id=100059866373276&modal=admin_todo_tour",
            naver_blog_url: "https://m.post.naver.com/my.nhn?memberNo=45344008",
            youtube_url:
                "https://www.youtube.com/channel/UCszTHIhyIBJtzLes4cHklHA?view_as=subscriber",
            excuted: false,

            imageNum: 0,
            imageIdx: "",
            recentimageIdx: "",
            switchScrollStyle: 0,
            scrollText1: false,
            scrollText2: false,
            scrollText3: false,
            scrollText4: false,
            scrollText5: false,
            scrollText6: false,
            scrollText7: false,
            scrollText8: false,
            scrollText9: false,
            scrollText10: false,
            scrollText11: false,
            scrollText12: false,
            scrollText13: false,
            scrollText14: false,
            scrollText15: false,
            scrollText16: false,
            scrollText17: false,
            scrollText18: false,
            scrollText19: false,
            scrollText20: false,
            scrollText21: false,
            scrollText22: false,
            scrollText23: false,
            scrollText24: false,
            scrollText25: false,
            scrollText26: false,
            scrollText27: false,
            scrollText28: false,
            scrollText29: false,
            scrollText30: false,
            scrollText31: false,
            scrollText32: false,
            scrollText33: false,
            scrollText34: false,
            scrollText35: false,
            scrollText36: false,
            scrollText37: false,
            scrollText38: false,
            scrollText39: false,
            scrollText40: false,
            scrollText41: false,
            scrollText42: false,
            scrollText43: false,
            scrollLeft: 0,
            scrollLeftSec: 0,
            scrollIcon: 200,
            scrollIconTow: 200,
            startLockScroll: 0,
            moveLockScroll: 0,
            activeIndex: 0,
            scrollLoacked: false,
            isDown: true,
            scrollEnded: true,
            recentIndex: 0,
            goingTop: false,
        };
    },
    created() {},
    mounted() {
        this.setImage();
        //한번만 실행되도록 설정
        let scrollContainer = document.getElementById("container");
        scrollContainer.addEventListener("scroll", this.scrollNum);
        scrollContainer.addEventListener("scroll", this.scrollToss);
        scrollContainer.addEventListener("wheel", this.scrollLockCheck);
    },
    beforeDestroy() {
        let scrollContainer = document.getElementById("container");
        scrollContainer.removeEventListener("scroll", this.scrollNum);
        scrollContainer.removeEventListener("scroll", this.scrollToss);
    },
    destroyed() {},
    computed: {},
    methods: {
        scrollLockCheck(e) {
            if (this.scrollLoacked && this.scrollEnded) {
                if (e.deltaY > 0 && this.activeIndex == 0) {
                    this.recentIndex = this.activeIndex;
                    this.activeIndex += 1;
                    this.scrollEnded = false;
                    setTimeout(() => (this.scrollEnded = true), 700);
                } else if (e.deltaY > 0 && this.activeIndex == 1) {
                    this.recentIndex = this.activeIndex;
                    this.activeIndex += 1;
                    this.isDown = false;
                    setTimeout(() => (this.scrollLoacked = false), 700);
                    this.scrollEnded = false;
                    setTimeout(() => (this.scrollEnded = true), 700);
                } else if (e.deltaY > 0 && this.activeIndex == 2) {
                    this.scrollLoacked = false;
                    this.scrollEnded = true;
                    this.isDown = false;
                } else if (e.deltaY < 0 && this.activeIndex == 2) {
                    this.recentIndex = this.activeIndex;
                    this.activeIndex -= 1;
                    this.scrollEnded = false;
                    setTimeout(() => (this.scrollEnded = true), 700);
                } else if (e.deltaY < 0 && this.activeIndex == 1) {
                    this.recentIndex = this.activeIndex;
                    this.activeIndex -= 1;
                    setTimeout(() => (this.scrollLoacked = false), 700);
                    this.scrollEnded = false;
                    this.isDown = true;
                    setTimeout(() => (this.scrollEnded = true), 700);
                } else if (e.deltaY < 0 && this.activeIndex == 0) {
                    this.scrollLoacked = false;
                    this.scrollEnded = true;
                    this.isDown = true;
                }
            }
        },
        scrollNum() {
            const targetElement = document.getElementById("boxCounter");

            if (!this.excuted) {
                const observer = new IntersectionObserver((entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            this.numAnimation();
                        }
                    });
                });
                observer.observe(targetElement);
            }
        },

        numAnimation() {
            let numAnimation = document.querySelectorAll(".count-eot-data");

            function changeNum(idx) {
                let intervalTime = 10;
                let targetNum = numAnimation[idx].getAttribute("data-rate");
                let endString = "";
                if (numAnimation[idx].classList.contains("he")) {
                    endString = "회";
                }
                if (numAnimation[idx].classList.contains("gun")) {
                    endString = "건";
                }

                let num = targetNum;
                let result = 0;

                let timer = setInterval(function () {
                    // ++num;
                    result = Math.ceil(targetNum - num);

                    numAnimation[idx].innerText =
                        result
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + endString;

                    if (num < 1) {
                        clearInterval(timer);
                    }
                    let step = num / 10;
                    num -= step;
                }, intervalTime);
            }

            for (let i = 0; i < numAnimation.length; i++) {
                changeNum(i);
            }
            this.excuted = true;
        },

        setImage() {
            if (this.imageNum != 19) {
                this.imageNum += 1;
            } else {
                this.imageNum = 1;
            }
            this.imageIdx = "img" + this.imageNum;
            if (this.imageNum == 1) {
                this.recentimageIdx = "img" + 19;
            } else {
                this.recentimageIdx = "img" + `${this.imageNum - 1}`;
            }
            let imgFlip = document.getElementById("imgFlip");
            let imgFlip2 = document.getElementById("imgFlip2");
            imgFlip.classList.add("trans");
            imgFlip2.classList.add("trans");
            setTimeout(() => imgFlip.classList.remove("trans"), 700);
            setTimeout(() => imgFlip2.classList.remove("trans"), 700);
            setTimeout(() => this.setImage(), 1500);
        },

        scrollTop() {
            let container = document.getElementById("container");
            container.scrollTo({ top: 0, behavior: "smooth" });
            this.goingTop = true;

            let scrollToLeftSec = document.getElementById("scrollTossSec");

            let scrollToLeft = document.getElementById("scrollToss");

            this.scrollIcon = 200;
            this.scrollIconTow = 200;
            this.isDown = true;
            this.activeIndex = 0;
            setTimeout(() => (this.goingTop = false), 700);
            setTimeout(() => (this.scrollEnded = true), 700);
            setTimeout(() => scrollToLeftSec.scrollTo({ left: 0 }), 700);
            setTimeout(() => scrollToLeft.scrollTo({ left: 0 }), 700);
            setTimeout(() => (this.isDown = true), 700);
            setTimeout(() => (this.activeIndex = 0), 700);
            setTimeout(() => (this.recentIndex = 0), 700);
            setTimeout(() => (this.scrollLoacked = false), 700);
        },

        onClickRedirect(url) {
            window.open(url, "_blank");
        },

        scrollToss() {
            let scrollContainer = document.getElementById("container");
            let scrollCheck = document.getElementById("tossScrollTop");
            let scrollToLeft = document.getElementById("scrollToss");
            let scrollSet = scrollCheck.offsetTop - window.innerHeight / 2;

            let scrollCheckSec = document.getElementById("tossScrollTopSec");
            let scrollToLeftSec = document.getElementById("scrollTossSec");
            let scrollSetSec =
                scrollCheckSec.offsetTop - window.innerHeight / 2;

            let scrollCheckThi = document.getElementById("tossScrollTopthi");
            let scrollSetThi =
                scrollCheckThi.offsetTop - window.innerHeight / 2;

            let scrollCheckFou = document.getElementById("tossScrollTopFou");
            let scrollSetFou =
                scrollCheckFou.offsetTop - window.innerHeight / 2;

            let scrollCheckFif = document.getElementById("tossScrollTopFif");
            let scrollSetFif =
                scrollCheckFif.offsetTop - window.innerHeight / 2;

            let scrollLock = document.getElementById("scrollLock");

            let scrollLockSet =
                scrollLock.offsetTop - window.innerHeight / 2 + 225;

            if (scrollContainer.scrollTop > scrollLockSet && this.isDown && !this.goingTop) {
                scrollContainer.scrollTop =
                    scrollLock.offsetTop - window.innerHeight / 2 + 225;
                this.scrollLoacked = true;
            }

            if (scrollContainer.scrollTop < scrollLockSet && !this.isDown && !this.goingTop) {
                scrollContainer.scrollTop =
                    scrollLock.offsetTop - window.innerHeight / 2 + 225;
                this.scrollLoacked = true;
            }

            if (
                scrollContainer.scrollTop > scrollSet + 450 &&
                scrollContainer.scrollTop < scrollSet + 800
            ) {
                this.scrollLeft = scrollContainer.scrollTop - scrollSet - 450;
                scrollToLeft.scrollTo({ left: this.scrollLeft * 1.2 });
            }

            if (
                scrollContainer.scrollTop > scrollSetSec + 450 &&
                scrollContainer.scrollTop < scrollSetSec + 800
            ) {
                this.scrollLeftSec =
                    scrollContainer.scrollTop - scrollSetSec - 450;
                scrollToLeftSec.scrollTo({ left: this.scrollLeftSec * 1.2 });
            }

            if (
                scrollContainer.scrollTop > scrollSetFou + 50 &&
                scrollContainer.scrollTop < scrollSetFou + 250
            ) {
                let scrollIconTrans = scrollContainer.scrollTop - scrollSetFou - 50;
                this.scrollIcon = 200 - scrollIconTrans;
            }

            if (
                scrollContainer.scrollTop > scrollSetFou + 200 &&
                scrollContainer.scrollTop < scrollSetFou + 400
            ) {
                let scrollIconTransTow = scrollContainer.scrollTop - scrollSetFou - 200;
                this.scrollIconTow = 200 - scrollIconTransTow;
            }

            this.scrollText1 = scrollContainer.scrollTop > scrollSet;
            this.scrollText2 = scrollContainer.scrollTop > scrollSet + 20;
            this.scrollText3 = scrollContainer.scrollTop > scrollSet + 40;
            this.scrollText4 = scrollContainer.scrollTop > scrollSet + 60;
            this.scrollText5 = scrollContainer.scrollTop > scrollSet + 80;
            this.scrollText6 = scrollContainer.scrollTop > scrollSet + 100;
            this.scrollText7 = scrollContainer.scrollTop > scrollSet + 120;
            this.scrollText8 = scrollContainer.scrollTop > scrollSet + 140;
            this.scrollText9 = scrollContainer.scrollTop > scrollSet + 160;

            this.scrollText10 = scrollContainer.scrollTop > scrollSetSec;
            this.scrollText11 = scrollContainer.scrollTop > scrollSetSec + 20;
            this.scrollText12 = scrollContainer.scrollTop > scrollSetSec + 40;
            this.scrollText13 = scrollContainer.scrollTop > scrollSetSec + 60;
            this.scrollText14 = scrollContainer.scrollTop > scrollSetSec + 80;
            this.scrollText15 = scrollContainer.scrollTop > scrollSetSec + 100;
            this.scrollText16 = scrollContainer.scrollTop > scrollSetSec + 120;
            this.scrollText17 = scrollContainer.scrollTop > scrollSetSec + 140;
            this.scrollText18 = scrollContainer.scrollTop > scrollSetSec + 160;
            this.scrollText19 = scrollContainer.scrollTop > scrollSetThi + 20;
            this.scrollText20 = scrollContainer.scrollTop > scrollSetThi + 40;
            this.scrollText21 = scrollContainer.scrollTop > scrollSetThi + 60;
            this.scrollText22 = scrollContainer.scrollTop > scrollSetThi + 80;
            this.scrollText23 = scrollContainer.scrollTop > scrollSetThi + 100;
            this.scrollText24 = scrollContainer.scrollTop > scrollSetThi + 120;
            this.scrollText25 = scrollContainer.scrollTop > scrollSetThi + 140;
            this.scrollText26 = scrollContainer.scrollTop > scrollSetThi + 160;
            this.scrollText27 = scrollContainer.scrollTop > scrollSetThi + 180;
            this.scrollText28 = scrollContainer.scrollTop > scrollSetThi + 200;
            this.scrollText29 = scrollContainer.scrollTop > scrollSetThi + 220;
            this.scrollText30 = scrollContainer.scrollTop > scrollSetThi + 240;
            this.scrollText31 = scrollContainer.scrollTop > scrollSetThi + 260;

            this.scrollText32 = scrollContainer.scrollTop > scrollSetFou;
            this.scrollText33 = scrollContainer.scrollTop > scrollSetFou + 20;
            this.scrollText34 = scrollContainer.scrollTop > scrollSetFou + 40;
            this.scrollText35 = scrollContainer.scrollTop > scrollSetFou + 60;

            this.scrollText36 = scrollContainer.scrollTop > scrollSetFif;
            this.scrollText37 = scrollContainer.scrollTop > scrollSetFif + 15;
            this.scrollText38 = scrollContainer.scrollTop > scrollSetFif + 30;
            this.scrollText39 = scrollContainer.scrollTop > scrollSetFif + 45;
            this.scrollText40 = scrollContainer.scrollTop > scrollSetFif + 60;
            this.scrollText41 = scrollContainer.scrollTop > scrollSetFif + 75;
            this.scrollText42 = scrollContainer.scrollTop > scrollSetFif + 90;
            this.scrollText43 = scrollContainer.scrollTop > scrollSetFif + 105;
        },

        CheckBrowser() {
            if (
                /iPad|iPhone|iPod|Macintosh/.test(navigator.userAgent) &&
                !window.MSStream
            ) {
                this.onClickRedirect(this.appStore_url);
            } else {
                this.onClickRedirect(this.playStore_url);
            }
        },

        endBtnArea() {
            this.scrollEnded = true;
        },

        setBtnArea(e) {
            this.moveLockScroll = e.changedTouches[0].clientY;
            if (this.scrollLoacked && this.scrollEnded) {
                if (
                    this.moveLockScroll < this.startLockScroll &&
                    this.activeIndex == 0
                ) {
                    this.activeIndex += 1;
                    this.scrollEnded = false;
                } else if (
                    this.moveLockScroll < this.startLockScroll &&
                    this.activeIndex == 1
                ) {
                    setTimeout(() => (this.scrollLoacked = false), 700);
                    this.scrollEnded = false;
                    this.isDown = false;
                    this.activeIndex += 1;
                } else if (
                    this.moveLockScroll < this.startLockScroll &&
                    this.activeIndex == 2
                ) {
                    this.scrollLoacked = false;
                    this.scrollEnded = true;
                    this.isDown = false;
                } else if (
                    this.moveLockScroll > this.startLockScroll &&
                    this.activeIndex == 2
                ) {
                    this.activeIndex -= 1;
                    this.scrollEnded = false;
                } else if (
                    this.moveLockScroll > this.startLockScroll &&
                    this.activeIndex == 1
                ) {
                    this.activeIndex -= 1;
                    setTimeout(() => (this.scrollLoacked = false), 700);
                    this.isDown = true;
                    this.scrollEnded = false;
                } else if (
                    this.moveLockScroll > this.startLockScroll &&
                    this.activeIndex == 0
                ) {
                    this.scrollLoacked = false;
                    this.scrollEnded = true;
                    this.isDown = true;
                }
            }
        },

        createAreaBtn(e) {
            this.startLockScroll = e.changedTouches[0].clientY;
        },
    },
    watch: {},
};
</script>
<style lang="scss"></style>
