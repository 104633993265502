<template>
    <modal name="alliance-modal" class="alliance-modal"
           :width="'100%'"
           :height="'100%'"
           :maxWidth="520"
           :maxHeight="550"
           :pivotY="0.5"
           :adaptive="true"
           :scrollable="true"
           @before-open="handlerBeforeOpen"
           @before-close="handlerBeforeClose"
           @opened="handlerOpened"
    >
        <div class="modal_wrap">
            <div class="modal_title">
                이옷 제휴 문의 안내
            </div>
            <div class="modal_content_container">
                <div class="modal_content">[office@e-ot.io]이메일로</div>
                <div class="modal_content">하단 양식을 포함하여 발송해주시면</div>
                <div class="modal_content">담당자가 확인 후 연락을 드리도록 하겠습니다.</div>
                <div class="modal_content pb9">감사합니다.</div>
            </div>

            <div class="modal_border_line"></div>

            <div class="modal_sub_title">양식</div>
            <div class="modal_content_container pb6">
                <div class="modal_content form">1. 브랜드명</div>
                <div class="modal_content form">2. 담당자명</div>
                <div class="modal_content form">3. 부서/직급</div>
                <div class="modal_content form">4. 휴대전화</div>
                <div class="modal_content form">5. 이메일</div>
                <div class="modal_content form">6. 자사몰 URL</div>
            </div>

            <div class="modal_border_line"></div>

            <div class="modal_sub_title">
                안내사항
            </div>

            <div class="modal_content_container">
                <div class="modal_content guide pb0">
                    -입점 후 MD/배송/CS 업무를 진행해 주실 담당자가 지정되어 있어야 합니다.<br/>
                    -이옷 사업 방향에 맞지 않는 브랜드의 입점은 진행하지 않습니다.<br/>
                    &nbsp&nbsp(병행수입 상품의 경우 수입신고 필증 보유 시 가능)<br/>
                    -입점 문의 시, 내부 검토 후에 입점 가능 여부 및 절차를 안내해드립니다.<br/>
                    -회사소개서 및 참고할 첨부자료가 있을시 메일에 함께 첨부하여 발송 바랍니다.
                </div>
            </div>
        </div>
        <el-button class="modal_close_btn" @click="modalClose"></el-button>
    </modal>
</template>

<script>
export default {
    name: "AllianceModal",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerBeforeClose() {
        },
        handlerOpened() {
        },
        modalClose() {
            this.$modal.hide('alliance-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
