<template>
    <div class="terms_text" v-html="termsText"></div>
</template>

<script>
import termsUtil from "@/components/terms/termsUtil";

export default {
    name: "TermsComponent",
    mixins: [termsUtil],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        termsType: '',
    },
    data() {
        return {
            termName: '',
            termsText: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        this.getTerms();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let termsConfig = this.returnTermsConfig(this.termsType);
            this.termName = termsConfig.termName;
        },

        getTerms() {
            try {
                this.$api.$common.getConfig().then(res => res.Data.Result).then(res => {
                    // console.log(res);
                    let termsInfo = res.List[0];
                    this.termsText = termsInfo[this.termName]
                })
            } catch (e) {
                // console.log(e);
            }
        },


    },
    watch: {},
}
</script>

<style scoped>

</style>
